@import '@varta/digital-styleguide/build/src/styles/base/_index';
@import '@varta/digital-styleguide/build/src/styles/base/_fonts';


// override
$fonts-path: './assets/fonts/';

@font-face {
    font-family: 'UniversLTStd';
    src: url(./assets/fonts/UniversLTStd-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'UniversLTStd';
    src: url(./assets/fonts/UniversLTStd-Bold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'UniversLTStd';
    src: url(./assets/fonts/UniversLTStd-XBlac.ttf) format('truetype');
    font-weight: 900;
}

body {
    overflow-x: hidden;
}

.body {
    font-family: UniversLTStd;
}

.varta-side-menu__title {
    font-weight: 600 !important;
}

.varta-leisure-wizard__teaser {
    .varta-text-image-cta__row {
        background-color: $color-white-shade;
        padding: 2em 1em;
    }

    .varta-text-image-cta__content-wrapper--overlay
    .varta-text-image-cta__content {
        background-color: $color-white;
    } 
}

.varta-leisure-frontpage__teaser {
    h3 {
        font-size: 40px;
    }
}
.varta-headline > span {
    hyphens: inherit !important;
}

@media print {
    .print-table-row {
        display: flex;
        flex-direction: row;
    }

    .print-page-break {
        page-break-before: always;
    }
    
    .print-no-show {
        display: none !important;
    }

    .print-show {
        display: block !important;
    }

    .print-no-break {
        break-inside: avoid;
    }

    .varta-leisure-result__col--data {
        width: 50% !important;
    }

    .varta-datasheet {
        border-width: 5px;
        border-style: solid;
    }
    
    .varta-datasheet--type-recommendation {
        border-color: $color-input-bg;
    }
    
    .varta-datasheet--type-upgrade {
        border-color: $color-grey-light-2;
    }
    
    .varta-datasheet__tooltip {
        border-width: 5px;
        border-style: solid;
        padding: 3px 10px 3px 10px;
    }

    .varta-datasheet--type-recommendation {
        .varta-datasheet__tooltip {
            border-color: $color-varta-blue;
        }
    }

    .varta-datasheet--type-upgrade {
        .varta-datasheet__tooltip {
            border-color: $color-varta-blue-light;
        }
    }

    .varta-datasheet__footer {
        display: none;
    }
}
