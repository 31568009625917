@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-result {
    &__row {
        @include row()
    }
    &__col {
        @include span(12);
        @include breakpoint('sm') {
            @include span(7);
        }
        &--data {
            width: 100%;
            @include breakpoint('md') {
                max-width: 50%;
                min-width: 33.3333%;
                flex: 1 1 0;
                padding: 0 .5em 0 .5em;

                // edge-case: 4 tiles
                &:nth-child(3):nth-last-child(2) {
                    min-width: 50%;
                }
            }
        }
    }

    &__spacer {
        height: 1.5em;
    }

    &__batteries {
        margin-right: 10px;
    }

    &__btnDownload {
        cursor: pointer;
        @include breakpoint('md') {
            width: 350px;
        }
    }

    &__side-menu {
        &__group {
            background-color: rgba($color-grey-2, 0.12);
        }

        &__item {
            position: relative;

            &--active {
                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    display: block;
                    position: absolute;
                    right: 0;
                    border: 10px solid transparent;
                    border-right-color: $color-white;
                    top: 50%;
                    margin-top: -10px;
                }
            }
        }
    }
}

// overridings
.varta-datasheet__image {
    width: auto !important;
    max-height: 203px;
}