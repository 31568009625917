@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-layout {
    &__header-widget {
        position: absolute;
        right: 0;

        @include breakpoint('lg') {
            display: none;
        }
    }

    &__footer {
        padding-top: 5em;
    }
}