@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-marine-wizard {
    &__row {
        @include row()
    }
    &__col {
        @include span(12);
        @include breakpoint('md') {
            @include span(6);
        }

        &--third {
            @include span(9);
            @include breakpoint('md') {
                @include span(4);
            }
        }
    
        &--quarter {
            @include span(12);

            @include breakpoint('md') {
                @include span(3);
                justify-content: flex-start;
            }
        }

        &--center {
            display: flex;
            align-items: center;
        }
    }

    &__cca-button {
        display: flex;
        align-items: center;
        padding-bottom: 7px;
    }
}