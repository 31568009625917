@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-camping-wizard {
    &__row {
        @include row();
    }

    &__col {
        @include span(12);
        display: flex;
        justify-content: center;

        @include breakpoint('md') {
            @include span(6);
            display: block;
        }

        &--third {
            @include span(9);
            @include breakpoint('md') {
                @include span(4);
            }
        }
    
        &--quarter {
            @include span(12);

            @include breakpoint('md') {
                @include span(3);
                justify-content: flex-start;
            }
        }

        &--center {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
    
    &__small {
        height: 8em;
        margin-bottom: 1em;
    }

    &__margin {
        margin-bottom: 1em;
    }

    &__close {
        margin-bottom: -2em;
    }

    &__button-full-width {
        // min-width: 300px;
        width: 100%;
    }

    &__home {
        border-top: 1px solid rgba($color-border, 0.3);
        margin-top: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 0 20px 0;

        &__button {
            @include buttonReset();
            color: $color-font-headline;
            fill: $color-varta-blue;
            display: flex;
            align-items: center;
            font-size: $font-size-xs;
            padding: 5px 20px;
            background: transparent;
            text-align: left;
            margin-top: 10px;
            position: relative;
        }

        &__icon {
            margin-right: 10px;
        }
    }

}