@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-language-widget {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 12px;

    &__button{
        display: flex;
        justify-content: center;
    }

    &__globe {
        margin-top: $font-offset;
    }
}