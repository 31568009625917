@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-frontpage {
    &__row {
        @include row()
    }
    &__col {
        @include span(12);
        @include breakpoint('sm') {
            @include span(6);
        }

        &--quarter {
            @include span(6);

            @include breakpoint('md') {
                @include span(3);
            }
        }
    }

    h1 {
        margin-top: 0;
    }

    &__imageDisabled {
        opacity: .5;
    }

    &__activity {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--button {
            margin: 1em 0;
            width: 100%;
        }
    }
}