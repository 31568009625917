@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-energy-consumer {
    &__row {
        @include row()
    }
    &__col {
        @include span(12);
        display: flex;
        justify-content: center;

        @include breakpoint('md') {
            @include span(6);
            display: block;
        }

        &--third {
            @include span(9);
            @include breakpoint('md') {
                @include span(4);
            }
        }
    
        &--quarter {
            @include span(12);
            width: 100%;

            @include breakpoint('md') {
                @include span(3);
            }
        }

        &--center {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &__full-width {
        width: 100%;
    }

}

// overridings
.varta-input {
    // width: 100%;
}