@import '@varta/digital-styleguide/build/src/styles/base/_index';

.varta-leisure-wizard {
    $c: &;

    &__layout {
        padding-bottom: 50vh !important;
    }

    &__header-image {
        height: auto;
        margin: 1em 0;

        @include breakpoint('md') {
            height: 400px;
            overflow: hidden;
    
            img {
                position: relative;
                top: -130px;
            }
        }
    }

    &__header-image--marine {
        height: auto;
        margin: 1em 0;

        @include breakpoint('md') {
            height: 400px;
            overflow: hidden;
    
            img {
                position: relative;
                top: -200px;
            }
        }
    }
 
    &__confirm-button-wrapper {
        display: flex;
    }

    &__confirm-button {
        margin-left: auto;
    }

    &__form-wrapper {
        padding-top: .5em;
        min-height: 350px;
    }

    &__spacer {
        height: 2em;

        @include breakpoint('md') {
            height: 5em;
        }
    }
}